body {
  margin: 0;
  font-family: "PoppinsLight";
  font-weight: 500;
  /* font-size: 0.875rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: "PoppinsLight"; */
}

/* @font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: normal;
} */
