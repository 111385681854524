html,
body,
#root {
  height: 100%;
}

.neeAppRoot {
  height: 100%;
}

.neeLogo {
  max-width: 60px;
  margin: 2px 2px;
  height: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* .fb_dialog.fb_dialog_advanced iframe {
  right: auto !important;
  bottom: 100px !important;
  left: 30px !important;
  min-height: 60px !important;
  min-width: 64px !important;
  max-height: 60px !important;
  max-width: 64px !important;
  padding: 0 !important;
  margin: 0 !important;
} */

.fb_dialog_content {
  background: #fff;
  color: #373737;
  display: none;
}
.fb_customer_chat_bubble_animated_no_badge {
  box-shadow: initial;
  transition: box-shadow 150ms linear;
}

.fb_customer_chat_bubble_animated_no_badge {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15) !important;
  transition: box-shadow 150ms linear;
}
